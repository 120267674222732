import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgHttpCachingService } from 'ng-http-caching';
import { forkJoin, map, switchMap } from 'rxjs';
import { Inquiry } from 'src/app/models/inquiry';
import { environment } from 'src/environments/environment';
import { nestedEwcToString } from 'src/app/shared/utils/adapters';
import { format } from 'date-fns';

export interface InquiryWasteType {
  gtin: string;
  sn: string;
  description: string;
  toxicity?: string | boolean;
  spec?: string;
}

export interface NestedEwc {
  group: string;
  description: string;
  subgroups: NestedEwcSubgroup[];
}

export interface NestedEwcSubgroup {
  subgroup: string;
  description: string;
  types: NestedEwcType[];
}

export interface NestedEwcType {
  ewc: string;
  toxicity: boolean;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class InquiryService {
  private http = inject(HttpClient);
  private header = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-NG-HTTP-CACHING-ALLOW-CACHE': 'true',
  });
  private ngHttpCachingService = inject(NgHttpCachingService);

  // INQUIRY
  public postInquiry(inquiry: Inquiry) {
    this.ngHttpCachingService.clearCacheByRegex(/inquiries/);
    return this.http.post<Inquiry>(
      environment.matchMakingApiUrl + '/v1/inquiries',
      inquiry
    );
  }

  public putInquiry(inquiry: Inquiry) {
    this.ngHttpCachingService.clearCacheByRegex(/inquiries/);
    return this.http.put<Inquiry>(
      environment.matchMakingApiUrl + '/v1/inquiries',
      inquiry
    );
  }

  public patchInquiry(id: string, partialInquiry: Partial<Inquiry>) {
    return this.getInquiry(id).pipe(
      switchMap((inquiry) => this.putInquiry({ ...inquiry, ...partialInquiry }))
    );
  }

  public getInquiry(id: string) {
    return this.http.get<Inquiry>(
      environment.matchMakingApiUrl + `/v1/inquiries/${id}`,
      { headers: this.header }
    );
  }

  public removeInquiry(id: string) {
    return this.http.delete(
      environment.matchMakingApiUrl + `/v1/inquiries/${id}`
    );
  }

  public removeInquiryElement(inquiryId: string, elementId: string) {
    return this.http.delete(
      environment.matchMakingApiUrl +
        `/v1/inquiries/${inquiryId}/elements/${elementId}`
    );
  }

  public getInquiries() {
    return this.http
      .get<Inquiry[]>(environment.matchMakingApiUrl + '/v1/inquiries', {
        headers: this.header,
      })
      .pipe(
        map((inquiries) =>
          inquiries
            .slice()
            .sort(
              (a, b) =>
                new Date(b.created).valueOf() - new Date(a.created).valueOf()
            )
        )
      );
  }

  public adminGetInquiries() {
    return this.http.get<Inquiry[]>(
      environment.matchMakingApiUrl + '/v1/admin/inquiries'
    );
  }

  public getInquiryById(id: string, caching = true) {
    return this.http.get<Inquiry>(
      environment.matchMakingApiUrl + `/v1/inquiries/${id}`,
      { headers: caching ? this.header : {} }
    );
  }

  public changeInquiryDeadline(inquiryId: string, deadline: Date) {
    return this.getInquiryById(inquiryId).pipe(
      switchMap((inquiry) =>
        this.putInquiry({
          ...inquiry,
          quotationDeadline: format(deadline, 'yyyy-MM-dd'),
        })
      )
    );
  }

  // WASTE TYPES

  public getWasteTypes() {
    return this.http.get<InquiryWasteType[]>(
      environment.matchMakingApiUrl + '/v1/wastecodes/aut/types',
      { headers: this.header }
    );
  }

  public getWasteTypeByGtin(gtin: string) {
    return this.getWasteTypes().pipe(
      map((wasteTypes) =>
        wasteTypes.find((wasteType) => wasteType.gtin === gtin)
      )
    );
  }

  public getEwcWasteTypes() {
    return this.http.get<NestedEwc[]>(
      environment.matchMakingApiUrl + '/v1/wastecodes/ewc/nested',
      { headers: this.header }
    );
  }

  public isWasteHazardous({
    ewcString,
    wasteString,
  }: {
    ewcString: string;
    wasteString: string;
  }) {
    return forkJoin({
      isWasteHazardous: this.isWasteTypeHazardous(wasteString),
      isEwcHazardous: this.isEwcHazardous(ewcString),
    }).pipe(
      map(
        ({ isEwcHazardous, isWasteHazardous }) =>
          isWasteHazardous || isEwcHazardous
      )
    );
  }

  public isEwcHazardous(ewcString: string) {
    return this.getEwcWasteTypes().pipe(
      map((groups) => {
        if (!ewcString) return false;
        for (let group of groups) {
          for (let subGroup of group.subgroups) {
            for (let type of subGroup.types) {
              if (nestedEwcToString(type) === ewcString) {
                return type.toxicity;
              }
            }
          }
        }
        return false;
      })
    );
  }

  public isWasteTypeHazardous(gtin: string) {
    return this.getWasteTypes().pipe(
      map((wastes) => {
        if (!gtin) return false;
        const waste = wastes.find((waste) => waste.gtin === gtin);
        return Boolean(waste?.toxicity);
      })
    );
  }
}
